








































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { searchConfig } from './hrcodelog.config';
import { ISearchConfig } from '@/components/SearchTable/index.d';
import { UserUploadService } from '@/services/user-uploading';
import { cloneDeep, isArray } from 'lodash';
import { uuid } from '@/services/utils';
import { DialogService } from '@/services/common/dialog.service';
import  hrlogSetting  from '@/views/hrcodelog/hrlogSetting.vue'



@Component
export default class Consent extends Vue {
  public searchFormLoading: boolean = false;
  public loading: boolean = false;

  // i18n
  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  @Inject(UserUploadService) private userUploadService!: UserUploadService;
    @Inject(DialogService) private dialogService!: DialogService;


  // private form: any = {};
  // private pagination: any = {
  //   pageSize: 10,
  //   total: 0,
  //   pageNum: 1,
  // };

  //定义表单参数
  // public pageInfo: any = {
  //   pageSize: 10,
  //   total: 0,
  //   pageNum: 1,
  //   chineseName: '',
  //   employeeId: '',
  //   companyEmail: '',
  //   divisionName: '',
  //   errorState: '',
  //   effectiveTime: '',
  //   timeFrom:'',
  //   timeTo:''
  // };

  private searchConfig: ISearchConfig[] = [];
  private tableData: Dict<any> = [];
  private eventList: any[] = [];
  private form: any = {};
  private key: any = '';
  private pagination: any = {
    pageSize: 10,
    total: 0,
    pageNum: 1
  };

  private async getList(val?: any, type?: string) {
    let form: any = { pageNum: 1, pageSize: 10 };
    if (type === 'pagination') {
      const { pageSize, pageNum } = val;
      form.pageNum = pageNum;
      form.pageSize = pageSize;
      form = Object.assign({}, this.form, form);
    } else if (type === 'search') {
      form = Object.assign(form, val);
      form.pageSize = 10;
      form.pageNum = 1;
    }
    if (form.effectiveTime && isArray(form.effectiveTime)) {
      form.timeFrom = form.effectiveTime[0];
      form.timeTo = form.effectiveTime[1];
    }else{
      form.timeFrom = '';
      form.timeTo = '';
    }
    this.form = form;
    try {
      this.loading = true;
      // const resupdateState = await this.userUploadService.getUpdateState(form);
      const res = await this.userUploadService.getPeopleList({
        pageNum: form.pageNum,
        pageSize: form.pageSize,
        total: form.total,
        chineseName: form.userName,
        employeeId: form.employeeId,
        companyEmail: form.email,
        divisionName: form.division,
        errorState: form.errorState,
        timeFrom: form.timeFrom,
        timeTo: form.timeTo
      });
      if (!res) {
        this.$notify.error({
          title: '错误',
          message: '获取列表失败，请您重试。'
        });
        return;
      }
      const { records, current, total, size } = res;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.pagination.pageNum = current;
      if (records && isArray(records)) {
        this.tableData = records;
        this.key = Date.now();
      }
    } finally {
      this.loading = false;
    }
  }


  private async initData() {
    this.searchConfig = cloneDeep(searchConfig);
    try {
      this.loading = true;
      await this.getList();
    } finally {
      this.loading = false;
    }
  }

  private async mounted(): Promise<void> {
    this.initData();
    const division: any =
      (await this.userUploadService.getDepartmentdivision()) || [];
    const divisionlist: any = [];
    division.forEach((item: any, index: number) => {
      divisionlist.push({
        id: item.deleted,
        label: item.division_name,
        name: item.id,
        value: item.division_name
      });
    });
    this.searchConfig[3].options = divisionlist;
  }
  
  //重置按钮
  private selectChange(val: Dict<any>): void {
    this.form = val;
  }

  private async updatePermision(): Promise<void> {
        const key = uuid();
        const res = await this.dialogService.open(
          '同步启用管理',
          hrlogSetting,
          { },
        )
  }
}
