



























































import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep, isArray } from 'lodash';
import { Inject } from '@cds/common';
import { DialogService } from '@/services/common/dialog.service';
import { UserUploadService } from '@/services/user-uploading';
import { DepartmentService } from '@/services/department-service';

@Component({})
export default class DepartmentSelector extends Vue {
  private radio: number = 1;
  private internalUserType: any = '内部员工';
  private externalUserType: any = '三方员工';
  private allInUserType: any = '所有部门';
  private allInDivision: any = '所有Division';
  public loading: boolean = false;
  public checkAll: boolean = false;
  public checkedDivisions: any[] = [];
  // public Divisions: any[] = [];
  public departmentTree: any[] = [];
  public divisionCheckBox: any[] = [];
  public defaultExpandedKeys: any = [];
  public defaultProps = {
    children: 'children',
    label: 'name'
  };

  @Prop({
    type: String,
    default: () => ''
  })
  private userType!: string;
  private divisionCode!: string;

  public selectedDepartmentList: any = [];

  @Inject(DialogService) private dialog!: DialogService;
  @Inject(UserUploadService) private userUploadService!: UserUploadService;
  @Inject(DepartmentService) private departmentService!: DepartmentService;

  public async created(): Promise<void> {
    await this.$nextTick();
    await this.initData();
  }

  public async initData() {
    this.loading = true;
    const departmentTree: any =
      (await this.departmentService.getDepartmentTreeAuthority()) || [];
    await this.getDefaultExpandedKeys(departmentTree);
    await this.initDefaultCheckedKeys(this.userType, departmentTree);
    this.departmentTree = cloneDeep(departmentTree);
    console.log('created', this.selectedDepartmentList);
    const divisionCheckBox: any =
      (await this.departmentService.getDivisionSelector()) || [];
    this.divisionCheckBox = cloneDeep(divisionCheckBox);
    this.divisionCheckBox.forEach((item: any) => {
      console.log('item', item);
      if (item.disable_state == 0) {
        this.checkedDivisions.push(item.division_code);
        console.log('this.checkedDivisions', this.checkedDivisions);
      }
    });
    this.loading = false;
  }

  //默认展开三级
  public getDefaultExpandedKeys(departmentTree: any) {
    let newDefaultExpandedKeys: any = [];
    if (
      departmentTree &&
      isArray(departmentTree) &&
      departmentTree.length > 0
    ) {
      departmentTree.forEach((tree: any) => {
        if (
          tree.children &&
          isArray(tree.children) &&
          tree.children.length > 0
        ) {
          tree.children.forEach((childTree: any) => {
            newDefaultExpandedKeys.push(childTree.dept_id);
          });
        } else {
          newDefaultExpandedKeys.push(tree.dept_id);
        }
      });
    }
    this.defaultExpandedKeys = newDefaultExpandedKeys;
  }

  public initDefaultCheckedKeys(userType: any, departmentTree: any) {
    if (
      departmentTree &&
      isArray(departmentTree) &&
      departmentTree.length > 0
    ) {
      departmentTree.forEach((item: any) => {
        if (userType == this.internalUserType && item.internal == 1) {
          this.selectedDepartmentList.push(item.dept_id);
          this.defaultExpandedKeys.push(item.dept_id);
          let children = item.children;
          if (children && children.length > 0) {
            children.forEach((child: any) => {
              this.$set(child, 'disabled', true);
              if (child.children && child.children.length > 0) {
                this.setChildNodeDisabled(child.children);
              }
            });
          }
        } else if (userType == this.externalUserType && item.external == 1) {
          this.selectedDepartmentList.push(item.dept_id);
          this.defaultExpandedKeys.push(item.dept_id);
          let children = item.children;
          if (children && children.length > 0) {
            children.forEach((child: any) => {
              this.$set(child, 'disabled', true);
              if (child.children && child.children.length > 0) {
                this.setChildNodeDisabled(child.children);
              }
            });
          }
        } else if (userType == this.allInUserType && !item.disable_state) {
          console.log('push', item);
          this.selectedDepartmentList.push(item.dept_id);
          this.defaultExpandedKeys.push(item.dept_id);
          let children = item.children;
          if (children && children.length > 0) {
            children.forEach((child: any) => {
              this.$set(child, 'disabled', true);
              if (child.children && child.children.length > 0) {
                this.setChildNodeDisabled(child.children);
              }
            });
          }
        } else {
          let children = item.children;
          if (children && children.length > 0) {
            this.initDefaultCheckedKeys(userType, children);
          }
        }
      });
    }
  }

  public getChildrenCheckedKeys(deptId: any, itemData: any) {
    if (itemData && isArray(itemData) && itemData.length > 0) {
      itemData.forEach((item: any) => {
        if (item.dept_id === deptId) {
          let children = item.children;
          if (children && children.length > 0) {
            children.forEach((child: any) => {
              //禁用
              this.$set(child, 'disabled', true);
              if (child.children && child.children.length > 0) {
                this.setChildNodeDisabled(child.children);
              }
            });
          }
        } else {
          let children = item.children;
          if (children && children.length > 0) {
            this.getChildrenCheckedKeys(deptId, children);
          }
        }
      });
    }
  }

  public setChildNodeDisabled(children: any) {
    children.forEach((child: any) => {
      this.$set(child, 'disabled', true);
      if (child.children && child.children.length > 0) {
        this.setChildNodeDisabled(child.children);
      }
    });
  }

  public async handleCheckChange(data: any, treeStatus: any) {
    console.log('handleCheckChange', data, 'treeStatus', treeStatus);
    if (treeStatus.checkedKeys.includes(data.dept_id)) {
      if (
        this.selectedDepartmentList.findIndex(
          (item: any) => item === data.dept_id
        ) === -1
      ) {
        this.selectedDepartmentList.push(data.dept_id);
      }
      if (data.children && data.children.length > 0) {
        await this.disabledChildrenOfData(data.children, true);
      }

      this.$emit('addDepartmentLog', {
        userType: this.userType,
        departmentData: data,
        newStatus: true
      });
    } else {
      if (
        this.selectedDepartmentList.findIndex(
          (item: any) => item === data.dept_id
        ) !== -1
      ) {
        this.selectedDepartmentList.splice(
          this.selectedDepartmentList.findIndex(
            (item: any) => item === data.dept_id
          ),
          1
        );
      }
      if (data.children && data.children.length > 0) {
        await this.disabledChildrenOfData(data.children, false);
      }
      this.$emit('addDepartmentLog', {
        userType: this.userType,
        departmentData: data,
        newStatus: false
      });
    }
  }
  public async handleCheckedDivisionsChange(value: any) {
    console.log(
      'handleCheckedDivisionsChange',
      value,
      'this.checkedDivisions',
      this.checkedDivisions
    );
    if (
      this.checkedDivisions.findIndex(
        (item: any) => item === value.division_code
      ) === -1
    ) {
      // this.checkedDivisions.push(value.division_code);
      this.$emit('addDivisionLog', {
        divisionCode: value.division_code,
        newState: false
      });
    } else if (
      this.checkedDivisions.findIndex(
        (item: any) => item === value.division_code
      ) !== -1
    ) {
      // this.checkedDivisions.splice(
      //   this.checkedDivisions.findIndex(
      //     (item: any) => item === value.division_code
      //   ),
      //   1
      // );
      this.$emit('addDivisionLog', {
        divisionCode: value.division_code,
        newState: true
      });
    }
  }
  public disabledChildrenOfData(children: any, flag: boolean) {
    if (children.length !== 0) {
      children.forEach((item: any) => {
        if (
          flag &&
          this.selectedDepartmentList.findIndex(
            (element: any) => element === item.dept_id
          ) !== -1
        ) {
          this.selectedDepartmentList.splice(
            this.selectedDepartmentList.findIndex(
              (element: any) => element === item.dept_id
            ),
            1
          );
          (this.$refs as any).tree3.setChecked(item, false, true);
        }
        this.$set(item, 'disabled', flag);
        if (
          item.children &&
          isArray(item.children) &&
          item.children.length > 0
        ) {
          this.disabledChildrenOfData(item.children, flag);
        } else {
          this.disabledChildrenOfData([], flag);
        }
      });
    }
  }
}
