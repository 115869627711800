




























import { Component, Vue } from 'vue-property-decorator';
import { I18nService } from '@cds/i18n';
import { Inject } from '@cds/common';
import { UserService } from '@/services/user-service';
import { DialogService } from '@/services/common/dialog.service';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { UserUploadService } from '@/services/user-uploading';
import DepartmentSelector from '@/views/hrcodelog/DepartmentSelector.vue';
import { cloneDeep } from 'lodash';
import { DepartmentService } from '@/services/department-service';
import isArray from 'core-js/library/fn/array/is-array';

@Component({
  components: { DepartmentSelector }
})
export default class PermissionSelector extends Vue {
  private permissionUpdateList: any[] = [];
  private permissionDivisionList: any[] = [];

  private data!: any;
  private allInUserType: any = '所有部门';
  private allInDivision: any = '所有Division';
  public internalUserType: any = '内部员工';
  public externalUserType: any = '三方员工';

  public activeName: string = 'internalLabel';

  @Inject(UserService) private userService!: UserService;
  @Inject(I18nService) private i18nSvc!: I18nService;
  @Inject(DialogService) private dialog!: DialogService;
  @Inject(UserUploadService) private userUploadService!: UserUploadService;
  @Inject(DepartmentService) private departmentService!: DepartmentService;

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  public async created(): Promise<void> {
    this.initData(this.data);
  }

  private async initData(data: any): Promise<void> {
    console.log('initData', data);
  }

  public async submitForm(): Promise<any> {
    console.log('permissionUpdateList', this.permissionUpdateList);
    console.log('permissionDivisionList', this.permissionDivisionList);
    try {
      await this.$confirm(
        '启用的部门将被同步到企业微信通讯录，请确认是否要继续操作',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      );
      if (this.permissionUpdateList && this.permissionUpdateList.length > 0) {
        await this.departmentService.disableALlInDepartmentTreeAuthority(
          this.permissionUpdateList
        );
      }
      if (
        this.permissionDivisionList &&
        this.permissionDivisionList.length > 0
      ) {
        console.log(
          'this.permissionDivisionList.length',
          this.permissionDivisionList.length
        );
        await this.departmentService.disableDivisionAuthority(
          this.permissionDivisionList
        );
      }
      this.$notify({
        title: '成功',
        message: '保存成功',
        type: 'success'
      });
      this.dialog.close(
        CloseStatus.confirm,
        {
          permissionDivisionList: this.permissionDivisionList,
          permissionUpdateList: this.permissionUpdateList
        },
        // [...this.permissionDivisionList,...this.permissionUpdateList],
        this.data.key
      );
    } catch (e) {
      this.$notify.info({
        title: '取消',
        message: '更新取消'
      });
    }
  }

  // await this.departmentService.disableALlInDepartmentTreeAuthority(
  //         this.permissionUpdateList
  //       )

  //   await this.departmentService.disableDivisionAuthority(
  //           this.permissionDivisionList
  //       )

  public cancelForm(): void {
    this.dialog.close(CloseStatus.close, void 0, this.data.key);
  }

  public toggleTab(val: any): void {
    console.log('toggleTab', val);
  }

  public addDepartmentLog(data: any): void {
    console.log('addDepartmentLog', data);
    if (data.userType == this.allInUserType) {
      let updatedDepartmentLog = {
        deptId: data.departmentData.dept_id ? data.departmentData.dept_id : '',
        newStatus: data.newStatus
      };
      let existsItem = this.permissionUpdateList.find(
        x => x.deptId === updatedDepartmentLog.deptId
      );
      if (existsItem) {
        existsItem.newStatus = updatedDepartmentLog.newStatus;
      } else {
        this.permissionUpdateList.push(updatedDepartmentLog);
      }
      console.log('permissionUpdateList', this.permissionUpdateList);
    }
  }
  public addDivisionLog(data: any): void {
    console.log('addDivisionLog', data);
    let updatedDivisionLog = {
      divisionCode: data.divisionCode,
      newStatus: data.newState
    };
    let existsItem = this.permissionDivisionList.find(
      x => x.divisionCode === updatedDivisionLog.divisionCode
    );
    if (existsItem) {
      existsItem.newStatus = updatedDivisionLog.newStatus;
    } else {
      this.permissionDivisionList.push(updatedDivisionLog);
    }
    console.log('permissionDivisionList', this.permissionDivisionList);
  }
}
